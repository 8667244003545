import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridValueOptionsParams,
  GridExperimentalFeatures,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";

type Props = {
  itineraryList: { [key: string]: any }[] | [];
  handleClickEditOpen: (id: string) => void;
  handleDelete: (id: string) => void;
};
export default function ItineraryTable({
  itineraryList,
  handleClickEditOpen,
  handleDelete,
}: Props) {
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  };

  function getStartDate(params: GridValueOptionsParams) {
    return new Date(params.row?.start_date).toLocaleDateString();
  }
  function getEndDate(params: GridValueOptionsParams) {
    return new Date(params.row?.end_date).toLocaleDateString();
  }
  function convertUtcToLocalTime(params: GridValueOptionsParams) {
    console.log(params);

    if (
      !params.row?.time ||
      isNaN(parseInt(params.row?.time)) ||
      isNaN(parseInt(params.row?.time))
    ) {
      return params.row?.time;
    }
    const [hours, minutes] = params.row?.time.split(":");
    const utcDate = new Date();
    utcDate.setUTCHours(parseInt(hours));
    utcDate.setUTCMinutes(parseInt(minutes));
    const localTimeString = utcDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return localTimeString;
  }

  const columnsForItineraryList: GridColDef[] = [
    {
      field: "start_date",
      headerName: "Start Date",
      width: 300,
      // minWidth:200,
      editable: false,
      sortable: false,
      valueGetter: getStartDate,
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 300,
      // minWidth:200,
      editable: false,
      sortable: false,
      valueGetter: getEndDate,
    },
    {
      field: "name",
      headerName: "Name",
      width: 250,
      // minWidth:200,
      editable: false,
      sortable: false,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 250,
      // minWidth:200,
      editable: false,
      sortable: false,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: false,
      width: 250,
    },
    {
      field: "time",
      headerName: "Time",
      // width: 200,
      minWidth: 200,
      editable: false,
      sortable: false,
      valueGetter: convertUtcToLocalTime,
    },
    {
      field: "venue",
      headerName: "Venue",
      width: 250,
      // minWidth:200,
      editable: false,
      sortable: false,
    },
    {
      field: "id",
      headerName: "Action",
      // width: 200,
      minWidth: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Tooltip title="Edit" placement="top" arrow>
            <IconButton
              onClick={() => handleClickEditOpen(params.row)}
              color="info"
              aria-label="edit"
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="top" arrow>
            <IconButton
              onClick={() => handleDelete(params.row.id)}
              color="error"
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <Box sx={{ height: 650, width: "100%" }}>
      <DataGrid
        autoPageSize
        rows={itineraryList || []}
        columns={columnsForItineraryList}
        // pageSize={9}
        // rowsPerPageOptions={[9]}
        density="comfortable"
        checkboxSelection
        // disableSelectionOnClick
        experimentalFeatures={
          { newEditingApi: true } as Partial<GridExperimentalFeatures>
        }
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  );
}
